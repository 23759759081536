@import "src/variables/variable";

.contact-form-wrapper {
  border-radius: 25px 25px 0 0;
  background: #FFFFFF;
  padding: 44px 65px 52px 60px;
  margin-top: 100px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.form-label {
  color: #5B5B5B;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 109.5%; /* 21.9px */
  letter-spacing: 5.1px;
}

.form-input {
  background: transparent;
  border: none;
  outline: none;
  padding-bottom: 30px;
  border-bottom: 1px solid #5B5B5B;

  color: #5B5B5B;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 109.5%; /* 21.9px */
  letter-spacing: 5.1px;

  &_error {
    &::-webkit-input-placeholder {
      color: red;
    }
  }
}

.error {
  color: red;
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 109.5%; /* 21.9px */
  letter-spacing: 5.1px;
}

.form-button {
  width: 100%;
  background: transparent;
  outline: none;
  border-radius: 20px;
  border: 3px solid #FFA500;
  padding: 24px;
  margin: 0 auto;

  color: #5B5B5B;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 135%; /* 27px */
  letter-spacing: 3.8px;
  text-transform: uppercase;
  text-align: center;
  transition: all 0.3s;

  &:hover {
    color: white;
    background: #FFA500;
    border: 3px solid #FFA500;
  }

  &_disabled {
    width: 100%;
    opacity: 0.5;
    pointer-events: none;
  }
}

.scopes-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: flex-start;
  gap: 15px;
}

.scope-button {
  border-radius: 20px;
  border: 2px solid #c3c3c3;
  padding: 15px 20px;
  background: transparent;

  color: #5B5B5B;
  text-align: left;
  font-family: $font;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 109.5%; /* 21.9px */
  letter-spacing: 5.1px;
  transition: all 0.3s;

  &:hover {
    color: white;
    background: #FFA500;
    border: 2px solid #FFA500;
  }

  &_active {
    color: white;
    background: #FFA500;
    border: 2px solid #FFA500;
  }
}

@media screen and (max-width: 800px) {
  .contact-form-wrapper {
    padding: 34px 20px 32px 20px;
  }

  .form-label {
    font-size: 16px;
    font-style: normal;
  }

  .form-input {
    padding-bottom: 20px;
    border-bottom: 1px solid #c3c3c3;
    width: 100%;

    color: #c3c3c3;
    font-family: $font;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 109.5%; /* 21.9px */
    letter-spacing: 5.1px;

    &_error {
      &::-webkit-input-placeholder {
        color: red;
      }
    }
  }

  .error {
    color: red;
    font-family: $font;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 109.5%; /* 21.9px */
    letter-spacing: 5.1px;
  }

  .form-button {
    width: 100%;
    background: transparent;
    outline: none;
    border-radius: 10px;
    border: 2px solid #FFA500;
    padding: 15px;
    margin: 0 auto;

    color: #5B5B5B;
    font-family: $font;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 27px */
    letter-spacing: 3.8px;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s;

    &:hover {
      width: 100%;
      color: #FFA500;
      border: 2px solid #FFA500;
    }

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .scopes-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: flex-start;
    gap: 15px;
    flex-direction: column;
  }

  .scope-button {
    border-radius: 5px;
    border: 1px solid #c3c3c3;
    padding: 10px 15px;
    background: transparent;

    color: #c3c3c3;
    text-align: left;
    font-family: $font;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 109.5%; /* 21.9px */
    letter-spacing: 5.1px;
    transition: all 0.3s;

    &:hover {
      color: #fbda70;
      border: 1px solid #fbda70;
    }

    &_active {
      color: #fbda70;
      border: 1px solid #fbda70;
    }
  }
}

@media screen and (max-width: 340px) {
  .contact-form-wrapper {
    width: 100%;
    padding: 34px 10px 32px 10px;
  }
}

