@import "src/variables/variable";

.contact-card-wrapper {
  border-radius: 24px;
  background: #5B5B5B;
  width: 480px;
  height: 250px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    box-shadow: rgba(251, 218, 112, 0.35) 5px 5px 15px;
    scale: 110%;
  }
}

.manager-block_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.manager-title_center {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

@media screen and (max-width: 540px) {
  .contact-card-wrapper {
    width: 100%;
  }
}
