@import "src/variables/variable";

.export-wrapper {
  position: relative;
  z-index: 10;

  &--bg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    max-width: 100%;
  }
}

.export-top-banner {
  height: 100%;
  width: 100%;
  padding: 10% 3% 5% 10%;
  background: #FFFFFF;

  &--desc {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 3%;

    &-text {
      max-width: 816px;
    }

    &-img {
      max-width: 100%;
      margin-top: 30px;
    }
  }

  &--cards {
    display: flex;
    justify-content: center;
    padding-right: 5%;
    gap: 6rem;
  }
}

.text_logistic {
  color: #FFFFFF;
}

.export-logistic {
  background:
    linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url('../../assets/images/logisticBackground.png') center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5% 5%;

  &--cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 5%;
  }
}

.export-contact {
  padding: 5% 0;
  background: #5B5B5B;
}

@media screen and (max-width: 1500px) {
  .export-top-banner {
    height: 100%;
    width: 100%;
    padding: 10% 5%;
    background: #FFFFFF;

    &--desc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 3%;

      &-img {
        max-width: 70%;
        width: 50%;
        margin-top: 20px;
      }

      &-text {
        max-width: 816px;
      }
    }

    &--cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-right: 0;
      gap: 6rem;
    }
  }

  .export-logistic {
    background:
      linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      url('../../assets/images/exportLogisticBg.png') center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5% 5%;

    &--cards {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      gap: 5%;
    }
  }
}

@media screen and (max-width: 1080px) {
.export-top-banner--desc-img {
  display: none;
}
}

@media screen and (max-width: 1800px) {
  .export-top-banner {
    height: 100%;
    width: 100%;
    padding: 10% 5% 5% 10%;
    background: #FFFFFF;
  }
}

@media screen and (max-width: 800px) {
  .export-top-banner {
    height: 100%;
    width: 100%;
    padding: 25% 5% 10%;
    background: #FFFFFF;
  }

  .export-logistic {
    background:
      linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)),
      url('../../assets/images/exportLogisticBg.png') center;
    background-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10% 5%;

    &--cards {
      display: none;
    }
  }

  .export-contact {
    padding: 10% 0;
  }
}
