@import "src/variables/variable";

.header-wrapper {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 8vh;
  padding: 0 5%;
  transition: all 1s;
}

.header-wrapper-active {
  background: rgba(0, 0, 0, 0.5) !important;
}

.header-navigation {
  display: flex;
  gap: 44px;
  align-items: center;

  &--link {
    color: #fff;
    font-family:
            $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s;

    &:hover {
      border-bottom: 2px solid #FFA500;
    }
  }

  &-btn {
    visibility: hidden;
    opacity: 0;
    display: none;
  }
}

.header-navigation a.active {
  border-bottom: 2px solid #FFA500;
}

.lang-select {
  width: 90px;
  height: 41px;
  border-radius: 10px;
  background: transparent;
  border: white 1px solid;
  position: relative;
  cursor: pointer;

  color: #DADADA;
  font-family: $font;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
    &--global-svg {
      margin-right: 5px;
    }
    &--arrow-svg {
      margin-left: 5px;
    }
}
.lang-item {
  list-style: none;
    &--button {
      background: none;
      border: none;
      border-radius: 5px;
      margin-bottom: 5px;
      padding: 0 30px;

      color: #DADADA;
      font-family: $font;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: all 0.5s;
    }
  &--button:hover {
    color: #FFA500;
    background: rgba(36, 36, 36, 0.07);

  }
}
.select-menu {
  position: absolute;
  top: calc(100% + 5px);
  width: 90px;
  border-radius: 10px;
  padding: 10px 0;
  border: none;
  background: rgba(203, 203, 203, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: translateY(-10px);
  visibility: hidden;
  transition: 0.3s;
    &--active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
}

@media screen and (max-width: 1200px) {
  .header-navigation {
    display: flex;
    gap: 55px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    transition: 1s;
    transform: translateY(-120vh);
    background: black;

    &-responsive {
      transform: none;
    }

    &-btn {
      visibility: visible;
      opacity: 1;
      display: block;
      background: none;
      border: none;
      outline: none;

      svg {
        fill: #ffffff;
        stroke: #ffffff;
      }
    }

    &-close-btn {
      position: absolute;
      top: 25px;
      right: 50px;
    }
  }
}

@media screen and (max-width: 500px) {
  .header-logo {
    max-width: 171px;
  }
}
