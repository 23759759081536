@import "src/variables/variable";

.button {
  position: relative;
  overflow: hidden;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 30px;
  width: fit-content;
  padding: 7px 15px;
  border-radius: 68px;
  z-index: 5;

  color: #fff;
  font-family: $font;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 37.8px */
  letter-spacing: 5.32px;
  transition: all 0.3s;
  svg {
    opacity: 0.5;
    transition: all 0.3s;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(255, 255, 255, 0.5);
    transition: left 0.5s;
    z-index: -1;
  }

  &:hover {
    color: #5B5B5B;
    border: none;

    &:before {
      left: 0;
    }

    svg {
      opacity: 1;
      transform: rotate(90deg);

      path {
        stroke: #000000;
      }
    }
  }
}

.size_m {
  font-size: 20px;
}

.size_s {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .size_m {
    font-size: 16px;
    letter-spacing: 3.04px;
  }

  .size_s {
    font-size: 16px;
    letter-spacing: 3.04px;
  }
}






////////////////////



.darkButton {
  position: relative;
  overflow: hidden;
  background: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: 30px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 68px;
  z-index: 5;

  color: #5B5B5B;
  font-family: $font;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 37.8px */
  letter-spacing: 5.32px;
  transition: all 0.3s;
  svg {
    opacity: 0.5;
    transition: all 0.3s;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 100%;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: rgba(91, 91, 91, 0.7);
    transition: left 0.5s;
    z-index: -1;
  }

  &:hover {
    color: #FFFFFF;
    border: none;

    &:before {
      left: 0;
    }

    svg {
      opacity: 1;
      transform: rotate(90deg);

      path {
        stroke: #FFFFFF;
      }
    }
  }
}

.size_m {
  font-size: 18px;
}

.size_s {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .size_m {
    font-size: 14px;
    letter-spacing: 3.04px;
  }

  .size_s {
    font-size: 14px;
    letter-spacing: 3.04px;
  }
}
