@import "../../../variables/variable";

.accordion-wrapper {
  width: 100%;
  background: transparent;
}

.accordion {
  list-style-type: none;
}

.accordion-item {
  background: #FFFFFF;
}

.accordion-header {
  position: relative;
  width: 100%;
  min-height: 100px;
  background: #FFFFFF;
  border: 0;
  border-bottom: 1px solid #bcbcbc;
  text-align: left;
  cursor: pointer;
  color: #5B5B5B;
  font-family: $font;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 311.1%; /* 77.775px */
  letter-spacing: 0.48px;
  padding-left: 50px;
}
.accordion-header.button-active {
      .AccordionCloseIcon {
        z-index: 1;
        transform: rotate(180deg);
      }
    .AccordionIcon {
      transform: rotate(180deg);
      z-index: -20;
    }
}
.accordion-collapse {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}
.accordion-collapse.open {
  height: auto;
}

.accordion-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 25px 100px 25px 100px;
}
.accordion-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.accordion-text {
  color: #5B5B5B;
  font-size: 20px;
  font-family: $font;
  font-style: normal;
  font-weight: 400;
  line-height: 165.1%; /* 33.02px */
  letter-spacing: 2.1px;
  margin-top: 5px;
  margin-right: 10px;
  list-style: none;
}

.AccordionCloseIcon {
  position: absolute;
  right: 20px;
  top: 40px;
  transition: all 0.3s;
}

.AccordionIcon {
  position: absolute;
  right: 20px;
  top: 40px;
  transition: all 0.3s;
}

@media screen and (max-width: 840px) {
  .accordion-header {
    font-size: 17px;
    font-weight: 700;
    line-height: 311.1%; /* 52.887px */
    letter-spacing: 1px;
    padding-right: 100px;
  }
  .accordion-text {
    font-size: 12px;
  }
  .accordion-body {
    padding: 25px 50px 25px 50px;
  }
}
@media screen and (max-width: 450px) {
  .accordion-header {
    font-size: 15px;
    padding-right: 50px;
    padding-left: 10px;
  }
  .AccordionCloseIcon {
    right: 5px;
  }
  .AccordionIcon {
    right: 5px;
  }
  .accordion-body {
    padding: 20px 15px;
  }
}
