@import "src/variables/variable";

.footer-wrapper {
  background: black;
  color: white;
  padding: 35px 5% 15px 5%;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 13px;

  &--column-desc {
    display: flex;
    flex-direction: column;
    gap: 11px;
    max-width: 486px;

    &-logo {
      max-width: 278px;
    }

    &-text {
      color: #fff;
      text-align: justify;
      font-family: $font;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 184.3%; /* 25.802px */
      letter-spacing: 1.4px;
    }
  }

  &--column-nav {
    display: flex;
    gap: 100px;

    &-links {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &-link {
        color: #c3c3c3;
        text-align: left;
        font-family: $font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
        transition: all 0.3s;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }

  &--column {
    display: flex;
    flex-direction: column;

    &-title {
      margin-bottom: 20px;
      color: #FFA500;
      font-family: $font;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 135%; /* 27px */
      letter-spacing: 3.8px;
    }

    &-pages-links {
      display: flex;
      flex-direction: column;
      gap: 11px;
      list-style-type: none;
    }

    &-links {
      display: flex;
      flex-direction: column;
      gap: 15px;

      div {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &-link {
        text-decoration: none;
        color: #fff;
        font-size: 16px;
        font-family: $font;
        font-style: normal;
        font-weight: 400;
        transition: all 0.3s;

        &:hover {
          color: #aaa;
        }
      }
    }
  }
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .powered-by {
    display: flex;
    align-items: center;
    gap: 13px;

    color: #fff;
    text-align: center;
    font-family: $font;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
  }

  .policy {
    display: flex;
    gap: 30px;

    &-link {
      color: #c3c3c3;
      text-align: center;
      font-family: $font;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.32px;
      transition: all 0.3s;

      &:hover {
        color: #ffffff;
      }
    }
  }

  &--links {
    display: flex;
    align-items: center;
    gap: 20px;

    &-btn {
      background: none;
      border: none;
      outline: none;

      svg {
        fill: #FFA500;
        transition: all 0.3s;
      }

      &:hover {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .footer-content--column-nav {
    gap: 50px;
  }
}

@media screen and (max-width: 1024px) {
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 13px;
  }

  .footer-bottom {
    flex-wrap: wrap;
    gap: 20px;
  }
}

@media screen and (max-width: 800px) {
  .footer-wrapper {
    padding: 70px 5% 50px 5%;
  }

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 13px;

    &--column-nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;

    .powered-by {
      display: flex;
      align-items: center;
      gap: 13px;

      color: #fff;
      text-align: center;
      font-family: $font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.36px;

      &:nth-child(1) {
        order: 2;
      }
    }

    .policy {
      display: flex;
      gap: 30px;

      &-link {
        color: #c3c3c3;
        text-align: center;
        font-family: $font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
        transition: all 0.3s;

        &:hover {
          color: #ffffff;
        }
      }

      &:nth-child(2) {
        order: 3;
      }
    }

    &--links {
      display: flex;
      align-items: center;
      gap: 20px;

      &-btn {
        background: none;
        border: none;
        outline: none;

        svg {
          fill: #FFA500;
          transition: all 0.3s;
        }

        &:hover {
          svg {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;

    &--column-nav {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-links {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-link {
          font-size: 14px;
          letter-spacing: 0.28px;
        }
      }
    }

    &--column {
      display: flex;
      flex-direction: column;

      &-title {
        margin-bottom: 20px;
        letter-spacing: 3.04px;
        font-size: 16px;
      }

      &-links {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-link {
          font-size: 14px;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    flex-direction: column;

    .powered-by {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      color: #fff;
      text-align: center;
      font-family: $font;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.36px;

      &:nth-child(1) {
        order: 2;
      }
    }

    .policy {
      display: flex;
      gap: 30px;

      &-link {
        color: #c3c3c3;
        text-align: center;
        font-family: $font;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.18px;
        transition: all 0.3s;

        &:hover {
          color: #ffffff;
        }
      }

      &:nth-child(2) {
        order: 3;
      }
    }

    &--links {
      display: flex;
      align-items: center;
      gap: 20px;

      &-btn {
        background: none;
        border: none;
        outline: none;

        svg {
          fill: #FFA500;
          transition: all 0.3s;
        }

        &:hover {
          svg {
            fill: #ffffff;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 350px) {
  .footer-content--column-nav {
    flex-direction: column;

  }
}
