@import "src/variables/variable";

.manager-title {
  color: #fff;
  font-family: $font;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 135%; /* 27px */
  letter-spacing: 1.9px;
  margin-bottom: 2rem;

  &_main {
    font-family: $font;
    font-size: 1.5rem;
    letter-spacing: 2.28px;
  }

  &_yellow {
    color: #FFA500;
    font-family: $font;
    letter-spacing: 2.7px;

    &_main {
      font-family: $font;
      letter-spacing: 3.24px;
    }
  }
}

.manager-contacts {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &_main {
    flex-direction: row;
    gap: 1.5rem;
  }

  &--item {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    color: #fff;
    font-family: $font;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 157.938%; /* 31.588px */

    &_main {
      color: #fff;
      font-size: 1.4rem;
    }
  }
}

.contact-block-wrapper {
  padding: 2.5% 5%;
  background: #5B5B5B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5%;
}

.contact-block-desc {
  max-width: 923px;
}

.main-manager-block {
  margin-bottom: 5rem;
}

.managers-block {
  display: flex;
  justify-content: space-between;
}

.contact-block-img {
  position: relative;
  z-index: 5;

  &--border {
    z-index: -1;
    border: 2px solid #FFA500;
    border-radius: 25px 25px 0 0;
    width: 527px;
    height: 570px;

    position: absolute;
    top: -20px;
    right: -20px;
  }
}

@media screen and (max-width: 1200px) {
  .contact-block-wrapper {
    padding: 2.5% 5%;
    background: #2e2e2e;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 100px;
  }
}

@media screen and (max-width: 600px) {
  .contact-block-img {
    position: relative;
    z-index: 5;

    &--img {
      width: 300px;
    }

    &--border {
      z-index: -1;
      border: 1px solid #FFA500;
      border-radius: 25px 25px 0 0;
      width: 300px;
      height: 324px;

      position: absolute;
      top: -30px;
      right: -30px;
    }
  }
  .manager-contacts {
    &--item {
      font-size: 1.7rem;
      font-weight: 400;
    }
  }
}

@media screen and (max-width: 420px) {
  .manager-contacts {
    flex-direction: column;
  }
}


@media screen and (max-width: 400px) {
  .contact-block-img {
    position: relative;
    z-index: 5;
    display: none;

    &--img {
      width: 200px;
    }

    &--border {
      z-index: -1;
      border: 1px solid #FFA500;
      border-radius: 25px 25px 0 0;
      width: 200px;
      height: 234px;

      position: absolute;
      top: -30px;
      right: -30px;
    }
  }
}

